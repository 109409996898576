import { createApp } from "vue";
import { createPinia } from "pinia";

import ElementPlus from "element-plus";
import "@/assets/styles/elementPlus.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "@/assets/styles/index.scss";

import App from "./App.vue";
import router from "./router";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(ElementPlus, { size: "default" });

//Registration all icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount("#app");
