import { authService } from "@/services";

export const Guard = (to, from, next) => {
  const token = authService.getAuthToken();

  if (token) {
    next();
  } else {
    next({ name: "Login" });
  }
};
