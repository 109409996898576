import { createRouter, createWebHistory } from "vue-router";
import { Guard } from "@/router/hooks";

import Layout from "@/layout/Layout";

const useComponents = (path) => () => import(`@/views/${path}`);

const routes = [
  {
    path: "/:catchAll(.*)*",
    redirect: { name: "Campaign" },
  },
  {
    path: "/login",
    name: "Login",
    component: useComponents("Login"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/campaign",
    component: Layout,
    beforeEnter: Guard,
    children: [
      {
        path: "",
        name: "Campaign",
        component: () => import("@/components/Campaign/CampaignTable"),
        meta: {
          title: "Campaign",
        },
      },
      {
        path: "create",
        name: "CampaignCreate",
        component: () => import("@/components/Campaign/CampaignSettings"),
        meta: {
          title: "Campaign create",
        },
      },
      {
        path: ":id/settings",
        name: "CampaignSettings",
        component: () => import("@/components/Campaign/CampaignSettings"),
        meta: {
          title: "Campaign settings",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((from, to, next) => {
  document.title = from.meta.title;
  next();
});

export default router;
