<script setup>
import { useDictionaryStore, useProfileStore } from "@/stores";
import Navigation from "@/layout/components/Navigation";
import Loader from "@/layout/components/Loader";
import { ref } from "vue";

const { loadProfileInfo } = useProfileStore();
const { initDictionary } = useDictionaryStore();

const isLoading = ref(true);

const fetchRequiredData = () => {
  Promise.allSettled([loadProfileInfo(), initDictionary()]).finally(() => {
    isLoading.value = false;
  });
};

fetchRequiredData();
</script>

<template>
  <Loader v-if="isLoading" />
  <el-container v-else>
    <el-header>
      <Navigation />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>
