import axios from "axios";
import { authService } from "@/services";
import { api } from "@/services/http.service";

export class ApiRequestFactory {
  #axiosInstance;

  constructor() {
    this.#axiosInstance = axios.create();

    this.setInterceptors();
  }

  set baseUrl(url) {
    if (!url) throw new Error("BASE_URL can't be empty!");

    this.#axiosInstance.defaults.baseURL = url;
  }

  setInterceptors() {
    this.#axiosInstance.interceptors.request.use(
      (config) => {
        config.headers["X-Auth-Token"] = authService.getAuthToken();

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.#axiosInstance.interceptors.response.use(
      (response) => {
        const { headers, data } = response;

        if (headers["x-meta-count"]) {
          return {
            data: data.data,
            count: Number(headers["x-meta-count"]),
          };
        }

        if (data.code !== 0) {
          return Promise.reject(data.message);
        }

        return data;
      },
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.config &&
          !error.response.config.__isRetryRequest
        ) {
          api.user.logout(true);

          throw new Error(error);
        }

        return Promise.reject(error.response.data.message);
      }
    );
  }

  getInstance() {
    return this.#axiosInstance;
  }
}
