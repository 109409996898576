import { DictionaryRequest } from "@/api/dictionary/dictionary.request";
import { UserRequest } from "@/api/user/user.request";
import { CampaignRequest } from "@/api/campaign/campaign.request";

export class ApiResourcesFactory {
  getInstance(requestPerformer) {
    return {
      dictionary: new DictionaryRequest(requestPerformer),
      user: new UserRequest(requestPerformer),
      campaign: new CampaignRequest(requestPerformer),
    };
  }
}
