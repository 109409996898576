import { defineStore } from "pinia";
import { ref } from "vue";
import { api } from "@/services/http.service";

export const useDictionaryStore = defineStore("dictionaryForm", () => {
  const conditionList = ref([]);
  const duplicateBehaviourList = ref([]);
  const duplicateCheckLevelList = ref([]);
  const countryList = ref([]);
  const tagList = ref([]);
  const brandList = ref([]);

  //GETTERS
  const getBrandName = (code) => {
    return brandList.value.find((i) => i.code === code)?.name;
  };
  const getCountryName = (code) => {
    return countryList.value.find((i) => i.code === code)?.name;
  };
  const getRangModelTagList = () => {
    return tagList.value.filter((i) => /rkmDayli/i.test(i.tagName));
  };

  //ACTIONS
  const loadCountryList = async () => {
    const { data } = await api.dictionary.getCountryList();

    countryList.value = data;
  };
  const loadConditionList = async () => {
    const { data } = await api.dictionary.getConditionList();

    conditionList.value = data;
  };
  const loadDuplicateBehaviourList = async () => {
    const { data } = await api.dictionary.getDuplicateBehaviourList();

    duplicateBehaviourList.value = data;
  };
  const loadDuplicateCheckLevelList = async () => {
    const { data } = await api.dictionary.getDuplicateCheckLevelList();

    duplicateCheckLevelList.value = data;
  };
  const loadTagList = async () => {
    const { data } = await api.dictionary.getTagList();

    tagList.value = data;
  };
  const loadBrandList = async () => {
    const { data } = await api.dictionary.getBrandList();

    brandList.value = data;
  };

  const initDictionary = async () => {
    await Promise.allSettled([
      loadCountryList(),
      loadConditionList(),
      loadDuplicateBehaviourList(),
      loadDuplicateCheckLevelList(),
      loadTagList(),
      loadBrandList(),
    ]);
  };

  return {
    conditionList,
    duplicateBehaviourList,
    duplicateCheckLevelList,
    countryList,
    tagList,
    brandList,
    getRangModelTagList,
    getCountryName,
    getBrandName,
    initDictionary,
  };
});
