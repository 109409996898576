export class CampaignRequest {
  #api;

  constructor(requestPerformer) {
    this.#api = requestPerformer;
  }

  async getCampaignList({ limit, offset, ...filters }) {
    try {
      return await this.#api.get("/campaign", {
        params: { limit, offset, ...filters },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getCampaign(id) {
    try {
      return await this.#api.get(`/campaign/${id}`);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async createCampaign(formData) {
    try {
      return await this.#api.post("/campaign", formData);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async updateCampaign({ id, formData }) {
    try {
      return await this.#api.put(`/campaign/${id}`, formData);
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async changeCampaignActivity({ id, isActive }) {
    try {
      return await this.#api.patch(`/campaign/${id}/active`, { isActive });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
