import { API_HOST } from "@/config";
import { ApiResourcesFactory, ApiRequestFactory } from "@/api";

const apiResourcesFactory = new ApiResourcesFactory();
const apiRequestFactory = new ApiRequestFactory();

const http = apiRequestFactory.getInstance();

apiRequestFactory.baseUrl = API_HOST;

export const api = apiResourcesFactory.getInstance(http);
