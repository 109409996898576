<script setup>
import AppIcon from "@/components/App/AppIcon";
import { useProfileStore } from "@/stores";
import { api } from "@/services/http.service";

const { profile } = useProfileStore();

const onLogout = () => {
  api.user.logout();
};
</script>

<template>
  <AppIcon class="mr-5" name="logo" width="160" height="30" />
  <el-menu
    mode="horizontal"
    text-color="#A5A8B3"
    active-text-color="#1F2641"
    :ellipsis="false"
    :default-active="$route.path"
    router
  >
    <el-menu-item index="/campaign">
      <AppIcon name="megaphone" width="18" height="18" />
      <span class="ml-1">Campaign</span>
    </el-menu-item>
  </el-menu>
  <div class="Profile">
    <div>
      <div class="Profile-Name" v-text="profile.name" />
      <div class="Profile-Email" v-text="profile.email" />
    </div>
    <el-tooltip effect="light" content="Logout" placement="bottom-end">
      <el-button icon="SwitchButton" circle @click="onLogout" />
    </el-tooltip>
  </div>
</template>
