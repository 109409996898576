import { defineStore } from "pinia";
import { ref } from "vue";
import { api } from "@/services/http.service";

export const useProfileStore = defineStore("profileStore", () => {
  const profile = ref({});

  //ACTIONS
  const loadProfileInfo = async () => {
    const { data } = await api.user.getProfileInfo();

    profile.value = data;
  };

  return {
    profile,
    loadProfileInfo,
  };
});
