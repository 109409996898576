import { authService } from "@/services";
import router from "@/router";

export class UserRequest {
  #api;

  constructor(requestPerformer) {
    this.#api = requestPerformer;
  }

  async login(formData) {
    try {
      const { data } = await this.#api.post("/auth/login", formData);

      authService.setAuthToken(data.token);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async logout(isAuthError = false) {
    if (!isAuthError) await this.#api.post("/auth/logout");

    authService.removeAuthToken();

    await router.push({ name: "Login" });
  }

  async getProfileInfo() {
    try {
      return await this.#api.get("/profile");
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
